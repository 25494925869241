<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-else>
      <h5 class="text-center text-primary">View Teaching Session</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Choose date*</strong><br/>
            <b-calendar v-model="value" @context="onContext" locale="en-US" :date-info-fn="dateClass" start-weekday="1"></b-calendar><br/>
            <div class="mt-3" />
            <strong><span class="text-primary">*</span> Sessions are planned on highlighted dates</strong>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">Step 2: View details and mapping**</strong>
            <b-row>
              <b-col>
                <div><strong>Title:</strong> {{ sessionTitle }} </div>
                <div><strong>Time:</strong> {{ sessionTime }} </div>
                <div><strong>Agenda:</strong> <div style="white-space: pre-wrap;" v-html=generateHTMLString(sessionDescription)></div></div>
              </b-col>
            </b-row>
            <b-row>
              <b-container v-if="loadingMappingDetails">
                <div class="text-center">
                  <b-spinner label="Loading..." variant="warning"></b-spinner>
                </div>
              </b-container>

              <b-col v-if="!loadingMappingDetails">
                <div class="mt-4" />
                <strong>Responded students</strong>
                <b-list-group>
                  <b-list-group-item 
                    v-for="item in studentsAttendance" 
                    :key="item.email" 
                    :variant="item.preference.includes('absent') ? 'light' : ((typeof(selectedTeachers[item.user_id]) ==='undefined' || selectedTeachers[item.user_id] === null) ? 'warning' : 'default')">
                    <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>
                    {{item.label}}
                    <div><strong>Teached by: {{ typeof(selectedTeachers[item.user_id]) !== 'undefined'? selectedTeachers[item.user_id] + ' (' + selectedTeachingMethod[item.user_id] + ')': 'Not mapped' }}</strong></div>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col v-if="!loadingMappingDetails">
                <div class="mt-4" />
                <strong>Responded teachers</strong>
                <b-list-group>
                  <b-list-group-item 
                    v-for="item in teachersAttendance" 
                    :key="item.email"
                    :variant="item.preference.includes('absent') ? 'light' : 'default'">
                    <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>
                    {{generateNameLabel(item.given_name, item.family_name, item.nickname, item.email)}}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="!loadingMappingDetails">
                <div class="mt-3" />
                <b-form @submit.prevent="onSubmit">
                  <b-form-group id="input-group-1" label="Remarks" label-for="input-1" disabled>
                    <b-form-textarea
                      id="input-1"
                      type="text"
                      v-model="remarks"
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <strong><span class="text-primary">**</span> Only students/teachers who have set their attendance will be shown</strong>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <div class="mt-3" />
    </span>

    <div class="mt-3" />
  </div>
</template>

<script>
  import SessionService from '@/services/SessionService.js';
  import AttendanceStudentService from '@/services/AttendanceStudentService.js';
  import AttendanceService from '@/services/AttendanceService.js';
  import MapTeachingSessionService from '@/services/MapTeachingSessionService.js';

  export default {
    name: 'jadwal',
    title: "TPA Gembira - Session Management",
    data() {
      return {
        alertMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        dismissErrSecs: 5,
        dismissErrCountDown: 0,
        alertErrMessage: "",
        loading: false,
        loadingMappingDetails: false,
        value: '',
        context: null,
        dateWithSessionOptions: [],
        selectedTeachers: [],
        selectedTeachingMethod: [],
        teachersAvailableID: [],
        teachersAttendance: null,
        studentsAttendance: null,
        sessionTitle: '',
        sessionTime: '',
        sessionDescription: '',
        mappingStudents2Teacher: null,
        remarks: "",
        date:""
      }
    },
    created(){
      this.init();
    },
    methods: {
      dateClass(ymd, date) {
        if (this.dateWithSessionOptions.includes(date.getFullYear()+ '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2))){
          return 'table-info';
        } else {
          return '';
        }
      },
      generateHTMLString(textToCheck){
        if ((textToCheck == null) || (textToCheck.length == 0)) {
          return ''
        } else {
          /* eslint-disable-next-line */
          var expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;

          var regex = new RegExp(expression);
          var match = ''; var splitText = []; var startIndex = 0;

          while ((match = regex.exec(textToCheck)) != null) {
            splitText.push({text: textToCheck.substr(startIndex, (match.index - startIndex)), type: 'text'});               
            var cleanedLink = textToCheck.substr(match.index, (match[0].length));
            // cleanedLink = cleanedLink.replace(/^https?:\/\//,'');
            splitText.push({text: cleanedLink, type: 'link'});                
            startIndex = match.index + (match[0].length);               
          }
          if (startIndex < textToCheck.length) {
            splitText.push({text: textToCheck.substr(startIndex), type: 'text'});
          }

          let str ="";
          for (let i=0; i < splitText.length; i++){
            if (splitText[i].type == 'link'){
              str += "<a href='" + splitText[i].text + "'>" + splitText[i].text + "</a>"
            } else {
              str += splitText[i].text;
            }
          }
          return str;
        }
      },
      async onContext(ctx) {
        if (ctx.selectedYMD !== null && ctx.selectedYMD !== "" && this.date !== ctx.selectedYMD){
          // get all accounts who can attend
          this.loadingMappingDetails = true;
          this.context = ctx;
          this.date = ctx.selectedYMD;
          this.remarks = "";
          SessionService.getSessionDetails(ctx.selectedYMD)
            .then(
              (item => {
                this.sessionTime = item.time;
                this.sessionTitle = item.title;
                this.sessionDescription = item.description;
              })
            );

          this.teachersAttendance = await AttendanceService.getAttendanceOnADate(ctx.selectedYMD);
          this.studentsAttendance = this.generateNameLabels(await AttendanceStudentService.getAttendanceOnADate(ctx.selectedYMD));
          // attendance may not be accurate, only used to suggest if there are default option

          this.teachersAvailableID = [];
          this.selectedTeachers = [];
          this.selectedTeachingMethod = []
          let mapTeacher2Name = new Map();
          for (const teacherEntry of this.teachersAttendance.entries()) {
            if (!teacherEntry[1].preference.includes("absent")){
              this.teachersAvailableID.push(teacherEntry[1].user_id);
              mapTeacher2Name.set(teacherEntry[1].user_id, this.generateNameLabel(teacherEntry[1].given_name, teacherEntry[1].family_name, teacherEntry[1].nickname, teacherEntry[1].email));
            }
          }

          // get from database if such things exist (only for initial)
          const mappingObject = await MapTeachingSessionService.getMapping(ctx.selectedYMD);
          if (typeof(mappingObject)!=='undefined'){
            if (typeof(mappingObject.mapping) !== 'undefined'){
              var mappingStudentTeacherHelper = []; 
              var mappingStudentTeachingMethodHelper = [];
              mappingObject.mapping.forEach((item) => {
                mappingStudentTeacherHelper[item.user_id_student] = item.user_id_teacher;
                mappingStudentTeachingMethodHelper[item.user_id_student] = item.teachingMethod;
              });
              for (const studentEntry of this.studentsAttendance.entries()){
                let teacherID = mappingStudentTeacherHelper[studentEntry[1].user_id];
                if (typeof(teacherID) !== 'undefined' && this.teachersAvailableID.includes(teacherID)){
                  this.selectedTeachers[studentEntry[1].user_id] = mapTeacher2Name.get(teacherID); 
                  this.selectedTeachingMethod[studentEntry[1].user_id] = mappingStudentTeachingMethodHelper[studentEntry[1].user_id];
                }
              }
            }
            this.remarks = mappingObject.remarks;
          }
          this.loadingMappingDetails = false;
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.getDateWithSession()])
          .then(() => {
            this.loading = false;
          });
      },
      async getDateWithSession(){
        SessionService.getListSessions()
          .then(
            (list => {
              // users for visualization only
              for (let item of list){
                this.dateWithSessionOptions.push(item.date);
              }
            })
          );
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>